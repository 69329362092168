// Dependency
import React, { useState, useEffect, useRef } from "react"
import {graphql, Link} from "gatsby";

// Internationalization
//import i18n from "../../internationalization/i18n";
import { useTranslation } from 'react-i18next';

// Components
import Layout from '../../components/layouts';
import SectionTitle from '../../components/section-title/section-title';
import BlogPostGrid from "../../components/blog-post/blog-post-grid/blog-post-grid";
import Tags from "../../components/tags/tags";
import BlogPostList from "../../components/blog-post/blog-post-list/blog-post-list";
import Subscribe from "../../components/subscribe/subscribe";
import Hero from "../../components/hero/hero";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Assets
import HeaderCats from "../../components/header/cats";

// Dummy data
// const headerCats = [
//   {
//     name: 'Featured',
//     link: '/category',
//   },
//   {
//     name: 'Träning',
//     link: '/category',
//   },
//   {
//     name: 'Återhämtning',
//     link: '/category',
//   },
//   {
//     name: 'Stretching',
//     link: '/category',
//   },
//   {
//     name: 'Flow',
//     link: '/category',
//   },
// ];

// Template
const TopStories = (props) => {
    const pageData = props.data.datoCmsBlogPage;
    // Extract top story blog articles
    const topStoryBlogPostItems = props.data.topStory.nodes;
    //  Extract instagram feed data
    //const instagramFeedItems = props.data.allInstaNode.nodes;

    // Translation Function
    const { t } = useTranslation();


    // Template
    return (
        <Layout  seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
            <HeaderCats data={pageData.categories} />

            <div className="cd-max-width ">
                <div className="layout--sidebar cd-page-section">
                    <div className="content">
                        <section className="cd-blog-hide-mobile">
                        </section>
                        <section>
                            <SectionTitle title={`${t('blog.topStories')}`}color={"blue"}>
                                <Link to={`/${props.pageContext.country}/${t('slug.blog')}/`}><i className="material-icons">navigate_before</i></Link>
                            </SectionTitle>
                            <BlogPostGrid
                                columns={"three"}
                                data={topStoryBlogPostItems}
                            />
                        </section>
                    </div>

                    <div className="sidebar cd-blog-hide-mobile">


                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default TopStories;

// GraphQL to get Dato data
export const query = graphql`
  query ( $locale: String! = "en" ){ 
    datoCmsBlogPage (locale: {eq: $locale}){
      locale
      title
      desktopHero{
        fluid{
            ...GatsbyDatoCmsFluid
          }
      }
      mobileHero{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      categories{
        id
        name
        slug
        locale
      }
      seoMetaTags {
        tags
        id
      }
    }
    topStory: allDatoCmsArticle(
      filter: {locale: {eq: $locale}, topStory: {eq: true}, title: {ne: null}}
      limit: 8
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        topStory
        slug
        seoSlug
        date
        readingTime
        id
        featuredMedia {
           fluid{
              ...GatsbyDatoCmsFluid
            }
          title
        }
        categories {
          name
          slug
          id
        }
        author {
          id
          name
           featuredImage {
              fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;